import React, { useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import toasted from "../config/toast";
import { useNavigate } from "react-router-dom";

const Trade = () => {

  const navigate = useNavigate();

  const [allFields, setAllFields] = useState({
    symbol: '',
    market_trade_type: 'buy', 
    open_price: '',
    close_price: '',
    profit_loss: ''
  });

  const HandleInputFields = (e: any) => {
    const { name, value } = e.target;
  
    let updatedFields = {
      ...allFields,
      [name]: value,
    };
  
    if (name === 'open_price' || name === 'close_price' || name === 'market_trade_type') {
      const { open_price, close_price, market_trade_type } = updatedFields;
  
      const openPrice = parseFloat(open_price);
      const closePrice = parseFloat(close_price);
  
      if (!isNaN(openPrice) && !isNaN(closePrice)) {
        let profitLossPercentage = 0;
  
        if (market_trade_type === 'buy') {
          profitLossPercentage = ((closePrice - openPrice) / openPrice) * 100;
        } else if (market_trade_type === 'sell') {
          profitLossPercentage = ((openPrice - closePrice) / openPrice) * 100;
        }
  
        updatedFields.profit_loss = profitLossPercentage.toFixed(2) + '%';
      } else {
        updatedFields.profit_loss = '';
      }
    }
  
    setAllFields(updatedFields);
  };

  const submitData = async (e: any) => {
    e.preventDefault(); 
    const { profit_loss, ...dataToSend } = allFields
    let data = await _fetch(`${api_url}trade/tradeData`, 'POST', dataToSend, {});
    if (data.status === 'success') {
      toasted.success(data?.message);
      navigate('/trade-history');
    }
    else {
      toasted.error(data?.message);
    }
  }
  
  const profitLossValue = parseFloat(allFields.profit_loss);
  const profitLossColor = profitLossValue > 0 ? 'green' : profitLossValue < 0 ? 'red' : 'black';

  return (
    <div>
      <div className="form-sec margin_top">
        <div className="coustom_container">
          <div className="inner-form">
            <form
            //  action="POST" onSubmit={submitData}
            >
              <div className="form_header">
                Trade Price
              </div>
              <div className="fields">
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Symbol"
                    name="symbol"
                    onChange={HandleInputFields}
                  />
                </div>
                <div className="form-group">
                  <select className="input-fields" name="market_trade_type" onChange={HandleInputFields}>
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Open Price"
                    name="open_price"
                    onChange={HandleInputFields}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Close Price"
                    name="close_price"
                    onChange={HandleInputFields}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Profit Or Loss Amount"
                    name="profit_loss"
                    value={allFields.profit_loss}
                    readOnly
                    style={{ color: profitLossColor }} 
                  />
                </div>
                {/* <div className="form-group">
                  <select className="input-fields" name="Country" id="Country">
                    <option value="India">credit</option>
                    <option value="London">Deposit</option>
                  </select>
                </div> */}
                <div className="form-group">
                  <button type="submit" className="button_form" onClick={submitData}>
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
