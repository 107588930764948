import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/images/logo.png";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import toasted from "../config/toast";

const Header = () => {


  const [RightSIdebar, setRightSIdebar] = useState(false);
  const onOpenRightSIdebar = () => setRightSIdebar(true);
  const onCloseRightSIdebar = () => setRightSIdebar(false);

  const navigate = useNavigate();
  let location = useLocation();

  const togglerBar = () => {
    const a: HTMLElement | any = document.body;
    a.classList.toggle("show_header__mobail");
  };

  const togglerBarRemove = () => {
    const aRemove: HTMLElement | any = document.body;
    aRemove.classList.remove("show_header__mobail");
  };

  useEffect(() => {

    // if (auth === "true") {
    (async () => {

      let res = await _fetch(`${api_url}home/dashboard`, "GET", {})
      if (res?.status === "success") {
      }
      else {
        localStorage.clear();
        navigate("/login");
      }
    })();
    // }
  }, [location.pathname]);


  const Logout = async () => {

    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});

    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }

  return (
    <>
      <div className="header-sec">
        <div className="coustom_container">
          <div className="inner-sec">
            <div className="left">
              <div className="logo">
                <Link to="/dashboard">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="links">
                <Link to="/dashboard" className="nav_links active">
                  Dashboard
                </Link>

                <div className="dropdown">
                  <div className="nav_links">Participants</div>

                  <div className="dropdown-menu">
                    <Link to="/user">
                      All Participants <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/paidUsers">
                      Active Participants <KeyboardArrowRightTwoToneIcon />
                    </Link>

                    <Link to="/inactiveUsers">
                      Inactive Participants <KeyboardArrowRightTwoToneIcon />
                    </Link>

                    <Link to="/blockedUsers">
                      Blocked Participants <KeyboardArrowRightTwoToneIcon />
                    </Link>

                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Settings</div>
                  <div className="dropdown-menu">
                    <Link to="/news">
                      News <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/popup_upload">
                      User Popup Banner <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/roiList">
                      ROI List <KeyboardArrowRightTwoToneIcon />
                    </Link>

                    <Link to="/withdrawsetting">
                      Settings <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/comissionpackage">
                      Commission Package  <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    



                    {/* <Link to="/WithdrawSettings">
                      Site Income Settings <KeyboardArrowRightTwoToneIcon />
                    </Link> */}
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Incomes</div>

                  <div className="dropdown-menu">
                    <Link to="/income/direct_income">
                      Direct Profit <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/income/level_income">
                      Layered Investment Returns <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/income/roi_income">
                      Profit on Investment <KeyboardArrowRightTwoToneIcon />
                    </Link>

                    <Link to="/income/royalty_income">
                      Royalty Profit <KeyboardArrowRightTwoToneIcon />
                    </Link>

                    <Link to="/income/global_lifetime_pool_income">
                      Eternal Global Pool Profit <KeyboardArrowRightTwoToneIcon />
                    </Link>

                    <Link to="/allincomes">
                      All Incomes <KeyboardArrowRightTwoToneIcon />
                    </Link>


                  </div>
                </div>



                <div className="dropdown">
                  <div className="nav_links">Trade Management</div>

                  <div className="dropdown-menu">
                    <Link to="/trade">
                      Trade <KeyboardArrowRightTwoToneIcon />
                    </Link>
                  {/* </div> */}
                  {/* <div className="dropdown-menu"> */}
                    <Link to="/trade-history">
                      Trade History<KeyboardArrowRightTwoToneIcon />
                    </Link>
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Withdraw</div>

                  <div className="dropdown-menu">
                    <Link to="/Withdraw">
                      Withdraw Request <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/Approved">
                      Approved Withdraw Request{" "}
                      <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/Pending">
                      Pending Withdraw Request
                      <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/Rejected">
                      Rejected Withdraw Request
                      <KeyboardArrowRightTwoToneIcon />
                    </Link>

                    {/* <Link to="/adminpending">
                      Admin Pending Payouts
                      <KeyboardArrowRightTwoToneIcon />
                    </Link> */}
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Mail</div>

                  <div className="dropdown-menu">
                    <Link to="/pending-tickets">
                      Pending Ticket <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    <Link to="/solved-ticket">
                      Solved Ticket <KeyboardArrowRightTwoToneIcon />
                    </Link>
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Deposit</div>

                  <div className="dropdown-menu">
                    <Link to="/SendWallet">
                      Fund Distribution  <KeyboardArrowRightTwoToneIcon />
                    </Link>
                    {/* <Link to="/SendIncomeWallet">
                      Send Credit Fund <KeyboardArrowRightTwoToneIcon />
                    </Link> */}
                    <Link to="/fund_history">
                      Fund History <KeyboardArrowRightTwoToneIcon />
                    </Link>
                  </div>
                </div>

              </div>
            </div>
            <div className="right_main">

              <div className="logout_btn">
                <button type="submit" className="main_button" onClick={Logout}>
                  Logout
                </button>
              </div>
              <div
                className="bar___pvt"
                onClick={() => {
                  onOpenRightSIdebar();
                  togglerBar();
                }}
              >
                <span className="line-toggle"></span>
                <span className="line-toggle"></span>
                <span className="line-toggle"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={RightSIdebar}
        onClose={() => {
          onCloseRightSIdebar();
          togglerBarRemove();
        }}
        classNames={{
          overlay: "P2P-PayOverlay",
          modal: "Header__sidebar__right",
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
        }}
        animationDuration={800}
      >
        <div className="right_header-sidebar">
          <div className="">
            <h4>More services</h4>
          </div>
          <div className="inner_links">
            <Link className="Responsive_link" to="/dashboard">
              Dashboard
            </Link>

            <Accordion className="mob_accordian">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon />
                  Participants
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/user">
                      <li> All Participants </li>
                    </Link>
                    <Link to="/paidUsers ">
                      <li> Active Participants</li>
                    </Link>
                    <Link to="/inactiveUsers">
                      <li>Inactive Participants</li>
                    </Link>

                    <Link to="/blockedUsers">
                      <li>Blocked Participants</li>
                    </Link>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> User Popup Image
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/news ">
                      <li>News</li>
                    </Link>
                    <Link to="/popup_upload">
                      <li>User Popup Banner</li>
                    </Link>
                    <Link to="/roiList">
                      <li>ROI List</li>
                    </Link>

                    <Link to="/withdrawsetting">
                      <li>Withdraw Settings</li>
                    </Link>

                    <Link to="/comissionpackage">
                      <li> Commission Package</li>
                    </Link>

                    {/* <Link to="/WithdrawSettings">
                      <li>Site Income Settings</li>
                    </Link> */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Incomes
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {/* <Link to="/trade_profit">
                      <li>Trade Profit</li>
                    </Link> */}


                    <Link to="/income/direct_income">
                      <li> Direct Profit</li>
                    </Link>
                    <Link to="/income/level_income">
                      <li>Layered Investment Returns</li>
                    </Link>
                    <Link to="/income/roi_income">
                      <li>  Profit on Investment </li>
                    </Link>

                    <Link to="/income/royalty_income">
                      <li> Royalty Profit </li>
                    </Link>


                    <Link to="/income/global_lifetime_pool_income">
                      <li>  Eternal Global Pool Profit </li>
                    </Link>



                    <Link to="/allincomes">
                      <li>All Incomes</li>
                    </Link>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Trade Management
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/trade">
                      <li>Trade</li>
                    </Link>
                    <Link to="/trade-history">
                      <li>Trade History</li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Withdraw
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/Withdraw">
                      <li>Withdraw Request</li>
                    </Link>
                    <Link to="/Approved">
                      <li>Approved Withdraw Request</li>
                    </Link>
                    <Link to="/Pending">
                      <li>Pending Withdraw Request</li>
                    </Link>
                    <Link to="/Rejected">
                      <li>Rejected Withdraw Request</li>
                    </Link>

                    {/* <Link to="/adminpending">
                      <li>Admin Pending Payouts</li>
                    </Link> */}

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Mail
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/pending-tickets">
                      <li>Pending Ticket</li>
                    </Link>
                    <Link to="/solved-ticket">
                      <li>Solved Ticket </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Deposit
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/SendWallet">
                      <li> Fund Distribution </li>
                    </Link>

                    {/* <Link to="/SendIncomeWallet">
                      <li>Send Credit Fund </li>
                    </Link> */}
                    <Link to="/fund_history">
                      <li> Fund History  </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <div className="text-center mt-4">
              <button type="submit" className="logout_button">
                logout
              </button>
            </div> */}
          </div>

          {/* <div className="light-dark">
            <Dropdown>
              <Dropdown.Toggle className="drop-button" id="dropdown-basic">
                <OverlayTrigger >
                placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='ps-3 pe-3 d-none'> ⌘ K on Mac / <br/> Ctrl K Windows </Tooltip>} >
                <div className="mode">
                            <NightsStay className="darkMode" />
                            <LightMode className="lightMode" />
                            <Deblur className="dimMode" />
                          </div>
                </OverlayTrigger>
                Change Modes
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href=""
                  className="Drop-link"
                  onClick={() => onDark("dark")}
                >
                  <NightsStay className="darkMode_1" /> Dark
                </Dropdown.Item>
                <Dropdown.Item
                  href=""
                  className="Drop-link"
                  onClick={() => {
                    onLight("light");
                  }}
                >
                  <LightMode className="lightMode_2" />
                  Light
                </Dropdown.Item>
                <Dropdown.Item
                  href=""
                  className="Drop-link"
                  onClick={() => {
                    onDim("dim");
                  }}
                >
                  <Deblur className="dimMode_1" /> Dim
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default Header;
