import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import toasted from "../config/toast";
import { useNavigate, useParams } from "react-router-dom";

const EditTrade = () => {
  const { _id } = useParams();

  const [symbolName, setSymbolName] = useState('');
  const [type, setType] = useState('');
  const [openPrice, setOpenPrice] = useState<number | ''>('');
  const [closePrice, setClosePrice] = useState<number | ''>('');
  const [profitLoss, setProfitLoss] = useState<string>('');
  const [profitLossColor, setProfitLossColor] = useState<string>('black');

  const navigate = useNavigate();
  useEffect(() => {
    getTradeData();
  }, []);

  const getTradeData = async () => {
    let data = await _fetch(`${api_url}trade/getsingle-trade/${_id}`, 'GET', {}, {});
    console.log('data', data);

    if (data.status === 'success') {
      toasted.success(data?.getTradeData);
      setSymbolName(data?.getTradeData[0]?.symbol);
      setType(data?.getTradeData[0]?.market_trade_type);
      setOpenPrice(data?.getTradeData[0]?.open_price);
      setClosePrice(data?.getTradeData[0]?.close_price);

      calculateProfitLoss(data?.getTradeData[0]?.open_price, data?.getTradeData[0]?.close_price, data?.getTradeData[0]?.market_trade_type);
    }
  };

  const calculateProfitLoss = (open: number | '', close: number | '', tradeType: string) => {
    if (open && close) {
      const openValue = Number(open);
      const closeValue = Number(close);
      let profitLossAmount: number;

      if (tradeType === 'buy') {
        profitLossAmount = ((closeValue - openValue) / openValue) * 100;
      } else if (tradeType === 'sell') {
        profitLossAmount = ((openValue - closeValue) / openValue) * 100;
      } else {
        profitLossAmount = 0;
      }

      setProfitLoss(profitLossAmount.toFixed(2) + '%');

      if (profitLossAmount > 0) {
        setProfitLossColor('green');
      } else if (profitLossAmount < 0) {
        setProfitLossColor('red');
      } else {
        setProfitLossColor('black');
      }
    } else {
      setProfitLoss('');
      setProfitLossColor('black');
    }
  };

  const handleOpenPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOpenPrice(value === '' ? '' : Number(value));
    calculateProfitLoss(value === '' ? '' : Number(value), closePrice, type);
  };

  const handleClosePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setClosePrice(value === '' ? '' : Number(value));
    calculateProfitLoss(openPrice, value === '' ? '' : Number(value), type);
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setType(value);
    calculateProfitLoss(openPrice, closePrice, value);
  };

  const submitData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let postData = {
      _id: _id,
      symbol: symbolName,
      market_trade_type: type,
      open_price: openPrice,
      close_price: closePrice,
    };

    let data = await _fetch(`${api_url}trade/update-trade`, 'POST', postData, {});
    if (data.status === 'success') {
      toasted.success(data?.message);
      navigate('/trade-history');
    } else {
      toasted.error(data?.message);
    }
  };

  return (
    <div>
      <div className="form-sec margin_top">
        <div className="coustom_container">
          <div className="inner-form">
            <form onSubmit={submitData}>
              <div className="form_header">Trade Price</div>
              <div className="fields">
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Symbol"
                    name="symbol"
                    onChange={(e) => setSymbolName(e.target.value)}
                    value={symbolName}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <select className="input-fields" name="market_trade_type" onChange={handleTypeChange} value={type}>
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Open Price"
                    name="open_price"
                    onChange={handleOpenPriceChange}
                    value={openPrice === '' ? '' : openPrice}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Close Price"
                    name="close_price"
                    onChange={handleClosePriceChange}
                    value={closePrice === '' ? '' : closePrice}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Profit Or Loss Amount"
                    name="profit_loss"
                    value={profitLoss}
                    readOnly
                    style={{ color: profitLossColor }}
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="button_form">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTrade;


