import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import LoadingBar from 'react-top-loading-bar';
import { Search } from "@mui/icons-material";
import toasted from "../config/toast";
import { useNavigate } from "react-router-dom";
import { FiberManualRecord, PersonRounded } from "@mui/icons-material";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";

const SolvedTickets = () => {


  const [open, setOpen] = useState(false);
  const [allSolvedTickets, setAllSolvedTickets] = useState([]);
  const [allData, setAllData] = useState({ totalTickets: "", pendingTickets: "", resoledTickets: "" });
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [keyCount, setKeycount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [searchedType, setSearchedType] = useState("username");
  const [searchedValue, setSearchedValue] = useState("");
  const [remark, setRemark] = useState("");
  const [ID, setID] = useState('');

  const Navigate = useNavigate();

  const onOpenModal = (id: any) => {
    setOpen(true);
    setID(id);
  }
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    getPendingTickets();
  }, [limit, page, searchedValue]);

  const getPendingTickets = async () => {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
      console.log("searchData", searchData);

    }
    else {
      searchData = "";
    }
    if (searchData) {
      let data = await _fetch(`${api_url}settings/resolvedTickets?sort=${sort}${searchData}`, "GET", {})
      if (data?.status === 'success') {
        setAllSolvedTickets(data?.data?.data);
        setAllData(data);
        setTotalData(data?.total);
        setProgress(100);
        setIsTrue(false);
      }
    }
    else {
      let data = await _fetch(`${api_url}settings/resolvedTickets?page=${page}&limit=${limit}&sort=${sort}${searchData}`, "GET", {})
      if (data?.status === 'success') {
        setAllSolvedTickets(data?.data?.data);
        setAllData(data);
        setTotalData(data?.total);
        setProgress(100);
        setIsTrue(false);
      }
    }
  }

  const handlePageClick = async (event: any) => {
    const newPage = (event.selected + 1);
    setPage(newPage);
  };

  const SubmitSolveTicket = async (e: any) => {
    e.preventDefault();
    let data = {
      "_id": ID,
      "remark": remark
    }
    let res = await _fetch(`${api_url}settings/solvePendingTicket`, "POST", data)

    if (res?.status === 'success') {
      toasted.success(res?.message);
      onCloseModal();
      getPendingTickets();
      // Navigate("/pending-tickets");
    }
    else {
      toasted.error(res?.message);
    }
  }

  return (
    <>
      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div>
        <div className="table-sec pb-4">
          <div className="table-sec-subheader">
            <div className="page_info">
              <div className="coustom_container">
                <div className="d-flex align-items-center justify-content-center">

                  <h6> <PersonRounded /> Ticket Details </h6>
                  <div className="inner_links_header">

                    <FiberManualRecord className="dot" />
                    <a href="#">Home</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="details-sec">
              <div className="coustom_container">
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-sm-6">
                    <div className="card table-details">
                      <div className="card-header">
                        <div className="card-imgs">
                          <img src={card_1} alt="icon-bg" />
                          <PersonRounded className="icon user" />
                        </div>
                        <p className="text-sm mb-0 text-capitalize">
                          Total Tickets
                        </p>
                      </div>
                      <div className="text-start pt-1">
                        <h4 className="active-txt"> <span className="de-sc"></span> {allData?.totalTickets}</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                    <div className="card table-details">
                      <div className="card-header">
                        <div className="card-imgs">
                          <img src={card_1} alt="icon-bg" />
                          <PersonRounded className="icon user" />
                        </div>
                        <p className="text-sm mb-0 text-capitalize">
                          Pending Tickets
                        </p>
                      </div>
                      <div className="text-start pt-1">
                        <h4 className="text-success"> <span className="de-sc"></span> {allData?.pendingTickets} </h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                    <div className="card table-details">
                      <div className="card-header">
                        <div className="card-imgs">
                          <img src={card_1} alt="icon-bg" />
                          <PersonRounded className="icon user" />
                        </div>
                        <p className="text-sm mb-0 text-capitalize">
                          Resolved Tickets
                        </p>
                      </div>
                      <div className="text-start pt-1">
                        <h4 className="text-warning"> <span className="de-sc"></span> {allData?.resoledTickets} </h4>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="coustom_container mb-5">
            <div className="table_main table_main_new">
              <div className="heading">
                <h1> Solved Tickets List</h1>
                <form action="">
                  <div className="form-group">
                    <select className="form-select" onChange={(e: any) => setSearchedType(e.target.value)}>
                      <option value={'username'}>Username</option>
                      <option value={'ticket_id'}>Ticket ID</option>

                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      className="input-fields"
                      type="search"
                      placeholder="Search"
                      onChange={(e: any) => setSearchedValue(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="table-sec-new">
                <div className="outer-table table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Ticket ID</th>
                        <th scope="col">Username</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Description</th>
                        <th scope="col">Image</th>
                        <th scope="col">status</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allSolvedTickets?.map((item: any, index: any) => {
                        return (
                          <>
                            <tr>
                              <td> {index + 1} </td>
                              <td> {item?.ticket_id} </td>
                              <td> {item?.username} </td>
                              <td> {item?.subject} </td>
                              <td>{item?.description}</td>
                              <td>
                                <img style={{ "width": " 100px" }} src={item?.image} />
                              </td>
                              <td>{item?.status === 0 ? "Pending" : item?.status}</td>
                              <td>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>

                  {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalData / 10}
                    previousLabel="<"
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>


        <Modal open={open} onClose={onCloseModal} center
          classNames={{
            overlay: "P2P-PayOverlay",
            modal: "Header__sidebar__right",
            modalAnimationIn: "customEnterModalAnimation",
            modalAnimationOut: "customLeaveModalAnimation",
          }}>

          <h4>Solve Ticket </h4>
          <div className="news-sec">
            <form onSubmit={SubmitSolveTicket}>
              <div className="mb-3">
                <label htmlFor="pwd" className="form-label">Remark</label>
                <input type="text" className="form-control" id="Description" placeholder="Enter Description" name="text" onChange={(e: any) => setRemark(e.target.value)} />
              </div>
              <button type="submit" className="btn submit-btn">Resolve</button>
            </form>
          </div>
        </Modal>

      </div>
    </>
  );
};

export default SolvedTickets;
