import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import LoadingBar from 'react-top-loading-bar';
import { FiberManualRecord, PersonRounded } from "@mui/icons-material";


const FundHistory = () => {

  const [fundHistory, setFundHistory] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [searchedType, setSearchedType] = useState("username");
  const [searchedValue, setSearchedValue] = useState("");
  const [sort, setSort] = useState('created_at');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);

  useEffect(() => {
    getFundHistory();
  }, [page, limit, searchedValue]);

  const getFundHistory = async () => {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;

    }
    else {
      searchData = "";
    }

    if (searchData) {
      let res = await _fetch(`${api_url}fund/fundHistory?sort=${sort}${searchData}`, "GET", {})
      if (res?.status === "success") {
        setFundHistory(res?.data?.data);
        setTotalRecords(res?.total);
        setProgress(100);
        setIsTrue(false);
      }
      if (page > 1) {
        setKeycount(0);
        var count = page - 1;
        setKeycount(count * limit);
      }
    }

    else {
      let res = await _fetch(`${api_url}fund/fundHistory?sort=${sort}&limit=${limit}&page=${page}`, "GET", {})
      if (res?.status === "success") {
        setFundHistory(res?.data?.data);
        setTotalRecords(res?.total);
        setProgress(100);
        setIsTrue(false);
      }
      if (page > 1) {
        setKeycount(0);
        var count = page - 1;
        setKeycount(count * limit);
      }
    }

  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setPage(newOffset);
  };

  return (
    <>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />
      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div>
        <div className="table-sec pb-4">
          <div className="table-sec-subheader">
            <div className="page_info">
              <div className="coustom_container">
                <div className="d-flex align-items-center justify-content-center">

                  <h6> <PersonRounded /> Fund Details </h6>
                  <div className="inner_links_header">

                    <FiberManualRecord className="dot" />
                    <a href="#">Home</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="coustom_container mb-5">
            <div className="table_main table_main_new">
              <div className="heading">
                <h1> Fund History</h1>
                <form action="">
                  <div className="form-group">
                    <select className="form-select" onChange={(e: any) => setSearchedType(e.target.value)}>
                      <option value={'username'}>Username</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      className="input-fields"
                      type="search"
                      placeholder="Search"
                      onChange={(e: any) => setSearchedValue(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div className="table-sec-new">
                <div className="outer-table table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">User ID</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Type</th>
                        <th scope="col">Credit Type</th>
                        <th scope="col">Remark</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fundHistory?.map((item: any, index: any) => {
                        return (
                          <>
                            <tr>
                              <td>{page > 1 ? index + keyCount + 1 : index + 1}</td>
                              <td>{item?.username}</td>
                              <td>${item?.amount?.toFixed(2)}</td>
                              <td> {item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</td>
                              <td>{item?.amount > 0 ? "Credit" : "Debit"}</td>
                              <td>{item?.remark}</td>
                              <td>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>

                  {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalRecords / 10}
                    previousLabel="<"
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundHistory;
