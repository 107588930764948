import { AttachMoneyRounded, MoreVertRounded, MenuRounded } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import avtar from '../Assets/images/user.png';
import no_data from '../Assets/images/no-data.png';
import { Dropdown } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useParams } from 'react-router-dom';
import _fetch from '../config/api';
import { api_url } from '../config/config';
import { CountryDropdown } from 'react-country-region-selector';
import LoadingBar from 'react-top-loading-bar'
import ReactPaginate from 'react-paginate';
import dateformat from "dateformat";
import toasted from '../config/toast';

const ViewUser = () => {

    const { username } = useParams();

    // input readonly on off user info and wallet 
    const [readOnly, setReadOnly] = useState(true);
    const [inputfocus, setInputfocus] = useState(false);
    const [readOnlywallet, setReadOnlywallet] = useState(true);
    const [inputfocuswallet, setinputfocuswallet] = useState(false);
    const [readOnlypassword, setReadOnlypassword] = useState(true);
    const [inputfocuspassword, setinputfocuspassword] = useState(false);
    const [allData, setAllData] = useState({ TotalDeposit: "", AvaliableDeposit: "", totalIncome: "", avaliableIncome: "", directIncome: "", levelIncome: "", poolIncome: "", roiIncome: "", totalRebuyWallet: "", royaltyIncome: "", totalTeam: "", totalActiveTeam: "", totalInactiveTeam: "", royaltyProfit: "", directProfit: "", layeredInvestmentReturns: "", eternalGlobalPoolProfit: "", profitOnInvestment: "", boosterProfit: "", sponserInfo: { user_info: { name: "", email: "", package: { current_package: "" } } }, username: "", userInfo: { user_info: { name: "", firstName: "", lastName: "", bio: "", wallet_addresses: { BEP20: "", TRC20: "" } } } });
    const [userData, setUserData] = useState({ email: "", phone: "", sponsor_id: "", username: "", directs: { total: "" } });
    const [sponsorData, setSponsorData] = useState({ username: "", created_at: "" });
    const [incomeData, setIncomeData] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [bio, setBio] = useState('');
    const [BEP20, setBEP20] = useState('');
    const [TRC20, setTRC20] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [country, setCountry] = useState('');
    const [progress, setProgress] = useState(0);
    const [isTrue, setIsTrue] = useState(true);
    const [selectedType, setSelectedType] = useState('direct_income');
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState('created_at');
    const [limit, setLimit] = useState(10);
    const [keyCount, setKeycount] = useState(0);
    const [itemOffset, setItemOffset] = useState(1);
    const [isCheckBlockStatus, setIsCheckBlockStatus] = useState(false);
    const [isCheckWithdrawStatus, setIsCheckWithdrawStatus] = useState(false);


    const [depositData, setDepositData] = useState([]);

    const readOnlyfuntion = () => {
        setReadOnly(false);
        setInputfocus(true);
    };

    const walletFocus = () => {
        setReadOnlywallet(false);
        setinputfocuswallet(true);
    };


    const PasswordFocus = () => {
        setReadOnlypassword(false);
        setinputfocuspassword(true);
    };

    const ToggleButton = () => {
        const add_class: HTMLElement | any = document.getElementById('tabs_sec');
        add_class.classList.toggle("only-responsive-show");
    }

    const ToggleButtonRemove = () => {
        const remove_class: HTMLElement | any = document.getElementById('tabs_sec');
        remove_class.classList.remove("only-responsive-show");
    }

    useEffect(() => {
        getUserAllData();
        getIncomeData(selectedType);
        getDepositData(selectedType)
    }, [itemOffset, limit]);

    const getUserAllData = async () => {

        setProgress(10);
        setIsTrue(true);

        let data = await _fetch(`${api_url}profile/getUser?username=${username}`, "GET", {})
        if (data?.status === "success") {

            setAllData(data?.data);
            setUserData(data?.data?.userInfo);
            setFirstName(data?.data?.userInfo?.user_info?.firstName);
            setLastName(data?.data?.userInfo?.user_info?.lastName);
            setEmail(data?.data?.userInfo?.email);
            setPhone(data?.data?.userInfo?.phone);
            setBio(data?.data?.userInfo?.user_info?.bio);
            setCountry(data?.data?.userInfo?.user_info?.country);
            setBEP20(data?.data?.userInfo?.user_info?.wallet_addresses?.BEP20);
            setTRC20(data?.data?.userInfo?.user_info?.wallet_addresses?.TRC20);
            setSponsorData(data?.data?.sponserInfo);
            setIsCheckBlockStatus(data?.data?.userInfo?.disable);
            setIsCheckWithdrawStatus(data?.data?.userInfo?.withdraw_status);

            setProgress(100);
            setIsTrue(false);
        }
    }

    const getIncomeData = async (type: any) => {

        let incomeData = await _fetch(`${api_url}income/userIncomeHistory?username=${username}&type=${type}&sort=${sort}&page=${itemOffset}&limit=${limit}`, "GET", {})
        if (incomeData?.status === "success") {
            setIncomeData(incomeData?.data?.data);
            setTotal(incomeData?.total);
        }
        if (itemOffset > 1) {
            setKeycount(0);
            var count = itemOffset - 1;
            setKeycount(count * limit);
        }
    }

    // userDepositHistory
    const getDepositData = async (type: any) => {
        let res = await _fetch(`${api_url}income/userDepositHistory?username=${username}&sort=${sort}&page=${itemOffset}&limit=${limit}`, "GET", {})
        if (res?.status === "success") {
            setDepositData(res?.data?.data);
            setTotal(res?.total);
        }
        if (itemOffset > 1) {
            setKeycount(0);
            var count = itemOffset - 1;
            setKeycount(count * limit);
        }
    }


    const handleTabClick = (type: any) => {
        setSelectedType(type);
        getIncomeData(type);
    };

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setItemOffset(newOffset);
    };

    const UserBlockStatus = async () => {
        let data = {
            "username": userData?.username,
            "disable": isCheckBlockStatus === false ? true : false
        }

        let res = await _fetch(`${api_url}profile/disableUser`, "POST", data)
        if (res?.status === "success") {
            toasted.success(res?.message);
        }
        else {
            toasted.error(res?.message);
        }
    }

    const CheckWithdraw = async () => {
        let data = {
            "username": userData?.username,
            "withdraw_status": isCheckWithdrawStatus === false ? true : false
        }

        let res = await _fetch(`${api_url}profile/withdrawalDisableUser`, "POST", data)
        if (res?.status === "success") {
            toasted.success(res?.message);
        }
        else {
            toasted.error(res?.message);
        }
    }

    const UpdateProfileData = async (e: any) => {
        e.preventDefault();

        let data = {
            "username": username,
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phone": phone ,
            "country": country,
            // "bio": bio ? bio : "",
        }

        let res = await _fetch(`${api_url}profile/updateProfile`, "POST", data, {});
        if (res?.status === "success") {
            toasted.success(res?.message);
        }
        else {
            toasted.error(res?.message);
        }
    }

    return (
        <>
            <LoadingBar
                color='var(--primary-color)'
                progress={progress}
                containerClassName="LoadingBar_contain"
                className="loaderbar-s"
                onLoaderFinished={() => setProgress(0)}
            />

            <div className={isTrue ? 'loader-bg' : ''}></div>

            <div className='view-main'>

                <div className='coustom_container'>
                    <div className='view-main-heading'>
                        <h5>User All Details</h5>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                            <li className="breadcrumb-item active"><a href="#">View User</a></li>
                        </ul>
                        <button type='button' className='btn toggle-button' onClick={ToggleButton}>
                            <MenuRounded />
                        </button>
                    </div>

                    <div className='view-u-sec'>
                        <div className='row main-row'>
                            <div className='col-12'>
                                <div className='row'>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Total Income</p>
                                                <p className='u-balanace'>{allData?.totalIncome >= "0" ? `$${Number(allData?.totalIncome)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Available Income</p>
                                                <p className='u-balanace'>{allData?.avaliableIncome >= "0" ? `$${Number(allData?.avaliableIncome)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Total Deposit</p>
                                                <p className='u-balanace'>{allData?.TotalDeposit >= "0" ? `$${Number(allData?.TotalDeposit)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Avaliable Fund</p>
                                                <p className='u-balanace'>{allData?.AvaliableDeposit >= "0" ? `$${Number(allData?.AvaliableDeposit)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Rebuy Wallet</p>
                                                <p className='u-balanace'>{allData?.totalRebuyWallet >= "0" ? `$${Number(allData?.totalRebuyWallet)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Direct Profit</p>
                                                <p className='u-balanace'>{allData?.directProfit >= "0" ? `$${Number(allData?.directProfit)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Layered Investment Returns</p>
                                                <p className='u-balanace'>{allData?.layeredInvestmentReturns >= "0" ? `$${Number(allData?.layeredInvestmentReturns)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Profit on Investment</p>
                                                <p className='u-balanace'>{allData?.profitOnInvestment >= "0" ? `$${Number(allData?.profitOnInvestment)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Royalty Profit</p>
                                                <p className='u-balanace'>{allData?.royaltyProfit >= "0" ? `$${Number(allData?.royaltyProfit)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Eternal Global Pool Profit</p>
                                                <p className='u-balanace'>{allData?.eternalGlobalPoolProfit >= "0" ? `$${Number(allData?.eternalGlobalPoolProfit)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-md-6'>
                                        <div className='card user-income-card'>
                                            <div className='u-income-left'>
                                                <p>Booster Profit</p>
                                                <p className='u-balanace'>{allData?.boosterProfit >= "0" ? `$${Number(allData?.boosterProfit)?.toFixed(2)}` : "-"}</p>
                                            </div>
                                            <div className='u-icome-ryt'>
                                                <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='col-12'>
                                <Tabs>

                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div className='card user-info'>
                                                <div className='card-header'>
                                                    <div className='d-flex align-items-start justify-content-between'>

                                                        <div className=''>
                                                            <div className='user-avtar'>
                                                                <img src={avtar} alt="user-img" width="100%" />
                                                            </div>
                                                            <p className='user_iD mb-0'>ID:{userData?.username}</p>
                                                        </div>


                                                        <div className='u-info-settings'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle key="start" id="dropdown-start">
                                                                    <MoreVertRounded />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className='dropdown-menu-start'>
                                                                    {isCheckBlockStatus === false ?
                                                                        < li > <button className="dropdown-item" type='button' onClick={UserBlockStatus}>Block</button></li>
                                                                        :
                                                                        < li > <button className="dropdown-item" type='button' onClick={UserBlockStatus}>Unblock</button></li>
                                                                    }
                                                                    {isCheckWithdrawStatus === false ?
                                                                        <li><button className="dropdown-item" type='button' onClick={CheckWithdraw}>Withdraw ON</button></li>
                                                                        :
                                                                        <li><button className="dropdown-item" type='button' onClick={CheckWithdraw}>Withdraw OFF</button></li>
                                                                    }

                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </div>
                                                    </div>

                                                    <div className='u-info-name'>
                                                        <div className=''>
                                                            <p>{allData?.userInfo?.user_info?.name ? allData?.userInfo?.user_info?.name : "-"}</p>
                                                            <h6>{allData?.userInfo?.user_info?.bio}</h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='card-body-sec'>
                                                    <div className='user-team-j1'>
                                                        <div className='j1-team'>
                                                            <p>Sponsor</p>
                                                            <h6>{allData?.sponserInfo?.user_info?.name} <br /> ( ID:<span>{userData?.sponsor_id}</span>) </h6>
                                                        </div>

                                                        <div className='j1-team'>
                                                            <p>Total Directs</p>
                                                            <h6>{userData?.directs?.total} </h6>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='' id='tabs_sec'>
                                                    <div className='overly-footer' onClick={ToggleButtonRemove}></div>
                                                    <div className='card-footer only-responsive' id='tabs_sec'>
                                                        <h5>How it work</h5>
                                                        <div className='main-tabs'>
                                                            <TabList>
                                                                <Tab>User Profile</Tab>
                                                                <Tab>Wallets</Tab>
                                                                <Tab>Team</Tab>
                                                                <Tab>Sponsor Infomation</Tab>
                                                                <Tab>Change Password</Tab>
                                                            </TabList>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-8'>
                                            <TabPanel>
                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='card-ui-heading mb-0'> User Information </h5>
                                                            <button type='button' className='btn edit-button' onClick={readOnlyfuntion}> Edit</button>
                                                        </div>

                                                        <div className={inputfocus ? 'card-body f-js-show' : 'card-body'} >
                                                            <form method="POST" onSubmit={UpdateProfileData}>
                                                                <div className='d-sm-flex justify-content-between align-items-center mb-3 gap-3'>
                                                                    <div className="w-100">
                                                                        <label htmlFor="fname" className="form-label">First Name</label>
                                                                        <input type="text" className="form-control" id="fname" placeholder="Enter First Name" name="fname" readOnly={readOnly} value={firstName} onChange={(e: any) => setFirstName(e.target.value)} />
                                                                    </div>

                                                                    <div className="w-100 fname-sec">
                                                                        <label htmlFor="lname" className="form-label">Last Name</label>
                                                                        <input type="text" className="form-control" id="lname" placeholder="Enter Last Name" name="lname" readOnly={readOnly} value={lastName} onChange={(e: any) => setLastName(e.target.value)} />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="email" className="form-label">Email</label>
                                                                    <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" readOnly={readOnly} value={email} onChange={(e: any) => setEmail(e.target.value)} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="phonenumber" className="form-label">Phone Number</label>
                                                                    <input type="text" className="form-control" id="phonenumber" placeholder="Enter Phone Number" name="phonenumber" readOnly={readOnly} value={phone} onChange={(e: any) => setPhone(e.target.value)} />
                                                                </div>


                                                                <div className="mb-3">
                                                                    <label htmlFor="pwd" className="form-label">Country</label>
                                                                    <CountryDropdown classes="form-select" value={country} onChange={(val: string) => setCountry(val)} />
                                                                </div>
{/* 
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="comment">Address</label>
                                                                    <textarea className="form-control" placeholder='Address' rows={5} id="comment" name="text" readOnly={readOnly} value={bio} onChange={(e: any) => setBio(e.target.value)} ></textarea>
                                                                </div> */}

                                                                {inputfocus &&

                                                                    <div className='btns-area d-flex justify-content-end align-items-center gap-3 mt-5'>
                                                                        <button type="button" className="btn btn-danger">cancel</button>
                                                                        <button type="submit" className="btn btn-success">Update</button>
                                                                    </div>
                                                                }

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </TabPanel>


                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='card-ui-heading mb-0'> Wallets </h5>
                                                            <button type='button' className='btn edit-button' onClick={walletFocus}> Edit</button>
                                                        </div>

                                                        <div className={inputfocuswallet ? 'card-body f-js-show' : 'card-body'} >
                                                            <form>
                                                                <div className="mb-3">
                                                                    <label htmlFor="address_1" className="form-label">BEP20 Wallet Address</label>
                                                                    <input type="text" className="form-control" id="address_1" placeholder="change BEP20 Wallet address" name="fname" readOnly={readOnlywallet} value={BEP20} onChange={(e: any) => setBEP20(e.target.value)} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="address_2" className="form-label">TRC20 Wallet Address</label>
                                                                    <input type="text" className="form-control" id="address_2" placeholder="change TRC20 Wallet address" name="lname" readOnly={readOnlywallet} value={TRC20} onChange={(e: any) => setTRC20(e.target.value)} />
                                                                </div>


                                                                {inputfocuswallet &&
                                                                    <div className='btns-area d-flex justify-content-end align-items-center gap-3 mt-5'>
                                                                        <button type="button" className="btn btn-danger">cancel</button>
                                                                        <button type="submit" className="btn btn-success">Update Wallet</button>
                                                                    </div>
                                                                }

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center mb-4'>
                                                            <h5 className='card-ui-heading mb-0'> Team</h5>
                                                        </div>

                                                        <div className='row'>

                                                            <div className='col-xl-4 col-md-6'>
                                                                <div className='card user-income-card'>
                                                                    <div className='u-income-left'>
                                                                        <p>Total Team</p>
                                                                        <p className='u-balanace'>{allData?.totalTeam}</p>
                                                                    </div>
                                                                    <div className='u-icome-ryt'>
                                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-xl-4 col-md-6'>
                                                                <div className='card user-income-card'>
                                                                    <div className='u-income-left'>
                                                                        <p>Active Team</p>
                                                                        <p className='u-balanace'>{allData?.totalActiveTeam}</p>
                                                                    </div>
                                                                    <div className='u-icome-ryt'>
                                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-xl-4 col-md-6'>
                                                                <div className='card user-income-card'>
                                                                    <div className='u-income-left'>
                                                                        <p>Inactive Team</p>
                                                                        <p className='u-balanace'>{allData?.totalInactiveTeam}</p>
                                                                    </div>
                                                                    <div className='u-icome-ryt'>
                                                                        <span className='u-icon-img-outer'> <AttachMoneyRounded /></span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='card-ui-heading mb-0'> Sponsor Information </h5>
                                                        </div>

                                                        <div className={inputfocus ? 'card-body f-js-show' : 'card-body'} >
                                                            <form>
                                                                <div className='d-sm-flex justify-content-between align-items-center mb-3 gap-3'>
                                                                    <div className="w-100">
                                                                        <label htmlFor="fname" className="form-label">Sponsor Username</label>
                                                                        <input type="text" className="form-control" id="fname" name="fname" readOnly={readOnly} value={sponsorData?.username} />
                                                                    </div>

                                                                    <div className="w-100 fname-sec">
                                                                        <label htmlFor="lname" className="form-label">Sponsor Name</label>
                                                                        <input type="text" className="form-control" id="lname" name="lname" readOnly={readOnly} value={allData?.sponserInfo?.user_info?.name} />
                                                                    </div>

                                                                    <div className="w-100 fname-sec">
                                                                        <label htmlFor="lname" className="form-label">Sponsor Email</label>
                                                                        <input type="email" className="form-control" id="lname" name="lname" readOnly={readOnly} value={allData?.sponserInfo?.user_info?.email} />
                                                                    </div>
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="email" className="form-label">Sponsor Package</label>
                                                                    <input type="Number" className="form-control" id="email" name="email" readOnly={readOnly} value={`$${allData?.sponserInfo?.user_info?.package?.current_package}`} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="phonenumber" className="form-label">Sponsor Register At</label>
                                                                    <input type="text" className="form-control" id="phonenumber" name="phonenumber" readOnly={readOnly} value={dateformat(sponsorData?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")} />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </TabPanel>

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card edit-user'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h5 className='card-ui-heading mb-0'> Chnage Password </h5>
                                                            <button type='button' className='btn edit-button' onClick={PasswordFocus}> Edit</button>
                                                        </div>

                                                        <div className={inputfocuspassword ? 'card-body f-js-show' : 'card-body'} >
                                                            <form>
                                                                <div className="mb-3">
                                                                    <label htmlFor="address_1" className="form-label"> Password </label>
                                                                    <input type="text" className="form-control" id="address_1" placeholder="password" name="fname" readOnly={readOnlypassword} value={password} onChange={(e: any) => setPassword(e.target.value)} />
                                                                </div>

                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="address_2" className="form-label">Confirm Password</label>
                                                                    <input type="text" className="form-control" id="address_2" placeholder="confirm password" name="lname" readOnly={readOnlypassword} value={confirmPassword} onChange={(e: any) => setConfirmPassword(e.target.value)} />
                                                                </div>


                                                                {inputfocuspassword &&
                                                                    <div className='btns-area d-flex justify-content-end align-items-center gap-3 mt-5'>
                                                                        <button type="button" className="btn btn-danger">cancel</button>
                                                                        <button type="submit" className="btn btn-success">Update Wallet</button>
                                                                    </div>
                                                                }

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </Tabs>
                            </div>


                            {/* history --- section */}

                            <div className='col-12'>
                                <Tabs>

                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div className='card user-info'>

                                                <div className=''>
                                                    <div className='card-footer border-0 mt-0'>
                                                        <h5>All History</h5>
                                                        <div className='main-tabs'>
                                                            <TabList>
                                                                <Tab onClick={() => handleTabClick('direct_income')}>Direct Profit</Tab>
                                                                <Tab onClick={() => handleTabClick('level_income')}> Layered Investment Returns</Tab>
                                                                <Tab onClick={() => handleTabClick('roi_income')}> Profit on Investment</Tab>
                                                                <Tab onClick={() => handleTabClick('royalty_income')}>  Royalty Profit</Tab>
                                                                <Tab onClick={() => handleTabClick('global_lifetime_pool_income')}> Eternal Global Pool Profit</Tab>
                                                                <Tab onClick={() => handleTabClick('booster_income')}> Booster Income</Tab>

                                                                <Tab>Deposit History</Tab>
                                                                <Tab>Withdraw History </Tab>
                                                                <Tab>Fund History</Tab>
                                                            </TabList>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-lg-8'>

                                            {/* Direct income tab */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Direct Profit</h5>
                                                        </div>

                                                        <div className='card-body'>

                                                            <div className='h-js-1' >
                                                                <div className='header-h-js hj-2'>

                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        {/* <li>Type</li> */}
                                                                        <li>Description</li>
                                                                        <li>Credit At</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData.length === 0 ? (
                                                                    <div className='no-data-js'>
                                                                        <img src={no_data} alt='no-data' width="80%" />
                                                                    </div>
                                                                )

                                                                    : (
                                                                        incomeData?.map((item: any, index: any) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='hj-2'>
                                                                                        <ul>
                                                                                            <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                            <li>${item?.amount?.toFixed(2)}</li>
                                                                                            {/* <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li> */}
                                                                                            <li>{item?.description}</li>
                                                                                            <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    )
                                                                }

                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            {/* level income tab */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Layered Investment Returns</h5>
                                                        </div>

                                                        <div className='card-body'>

                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData.length === 0 ? (
                                                                    <div className='no-data-js'>
                                                                        <img src={no_data} alt='no-data' width="80%" />
                                                                    </div>
                                                                )
                                                                    : (
                                                                        incomeData?.map((item: any, index: any) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='hj-2'>
                                                                                        <ul>
                                                                                            <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                            <li>${item?.amount?.toFixed(2)}</li>
                                                                                            <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                            <li>{item?.description}</li>
                                                                                            <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    )}

                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            {/* roi income tab */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Profit on Investment</h5>
                                                        </div>

                                                        <div className='card-body'>



                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData.length === 0 ? (
                                                                    <div className='no-data-js'>
                                                                        <img src={no_data} alt='no-data' width="80%" />
                                                                    </div>
                                                                )
                                                                    : (
                                                                        incomeData?.map((item: any, index: any) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='hj-2'>
                                                                                        <ul>
                                                                                            <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                            <li>${item?.amount?.toFixed(2)}</li>
                                                                                            <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                            <li>{item?.description}</li>
                                                                                            <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    )}


                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            {/* royality income tab */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Royalty Profit</h5>
                                                        </div>

                                                        <div className='card-body'>

                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData.length === 0 ? (
                                                                    <div className='no-data-js'>
                                                                        <img src={no_data} alt='no-data' width="80%" />
                                                                    </div>
                                                                )
                                                                    : (
                                                                        incomeData?.map((item: any, index: any) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='hj-2'>
                                                                                        <ul>
                                                                                            <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                            <li>${item?.amount?.toFixed(2)}</li>
                                                                                            <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                            <li>{item?.description}</li>
                                                                                            <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    )}
                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            {/* pool income tab */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Eternal Global Pool Profit</h5>
                                                        </div>

                                                        <div className='card-body'>

                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Type</li>
                                                                        <li>Description</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                {incomeData.length === 0 ? (
                                                                    <div className='no-data-js'>
                                                                        <img src={no_data} alt='no-data' width="80%" />
                                                                    </div>
                                                                )
                                                                    : (

                                                                        incomeData?.map((item: any, index: any) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='hj-2'>
                                                                                        <ul>
                                                                                            <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                            <li>${item?.amount?.toFixed(2)}</li>
                                                                                            <li>{item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                                                            <li>{item?.description}</li>
                                                                                            <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    )}

                                                            </div>
                                                            {total > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                                breakLabel="..."
                                                                nextLabel=">"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={1}
                                                                pageCount={total / 10}
                                                                previousLabel="<"
                                                            />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            {/* Deposit History */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Deposit History </h5>
                                                        </div>

                                                        <div className='card-body'>



                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Remark</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>




                                                                {depositData?.map((item: any, index: any) => {
                                                                    return (
                                                                        <>
                                                                            <div className='hj-2'>
                                                                                <ul>
                                                                                    <li>{itemOffset > 1 ? index + keyCount + 1 : index + 1}</li>
                                                                                    <li>${item?.amount?.toFixed(2)}</li>
                                                                                    <li>{item?.remark}</li>
                                                                                    <li>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}



                                                            </div>

                                                            <ul className="pagination justify-content-center mb-0">
                                                                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                            </ul>

                                                            {/* <div className='h-js-1'>
                                                                        <div className='no-data-js'>
                                                                            <img src={no_data} alt='no-data' width="80%" />
                                                                        </div>
                                                                    </div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            {/* Withdraw History */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Withdraw History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            {/* Fund History */}

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Fund History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                        </div>
                                        <div className='col-lg-8'>
                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Deposit History </h5>
                                                        </div>

                                                        <div className='card-body'>



                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>


                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div> <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div> <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div> <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>
                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>1</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>2</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>


                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>3</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>4</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>5</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>6</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>


                                                                <div className='hj-2'>
                                                                    <ul>
                                                                        <li>7</li>
                                                                        <li>$100.00</li>
                                                                        <li>15-12-2023 03:12:02 PM</li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <ul className="pagination justify-content-center mb-0">
                                                                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                                <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                                            </ul>

                                                            {/* <div className='h-js-1'>
                                                                        <div className='no-data-js'>
                                                                            <img src={no_data} alt='no-data' width="80%" />
                                                                        </div>
                                                                    </div> */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </TabPanel>

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Withdraw History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>

                                                <div className='card-view-11'>
                                                    <div className='card history-card'>

                                                        <div className='card-heading-history'>
                                                            <h5 className='card-ui-heading mb-2'>Fund History </h5>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='h-js-1'>
                                                                <div className='header-h-js hj-2'>
                                                                    <ul>
                                                                        <li>#</li>
                                                                        <li>Amount</li>
                                                                        <li>Date</li>
                                                                    </ul>
                                                                </div>

                                                                <div className='no-data-js'>
                                                                    <img src={no_data} alt='no-data' width="80%" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </Tabs>
                            </div>

                        </div>
                    </div >

                </div >
            </div >

        </>
    );
}

export default ViewUser;
