import React, { useEffect } from "react";
import "./Assets/css/style.scss";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Index from "./Public/Index";
import Header from "./Public/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Public/Login";
import Table from "./Public/Table";
import Form from "./Public/Form";
import Users from "./Public/Users";
import News from "./Public/News";
import WorkingPopup from "./Public/WorkingPopup";
import SiteIncome from "./Public/SiteIncome";
import WithdrawRequest from "./Public/WithdrawRequest";
import ApprovedWithdraw from "./Public/ApprovedWithdraw";
import PendingWithdraw from "./Public/PendingWithdraw";
import RejectedWithdraw from "./Public/RejectedWithdraw";
import AdminPendingwithdraw from "./Public/AdminPendingWithdraw";
import SolvedTickets from "./Public/SolvedTickets";
import SendDeposit from "./Public/SendDeposit";
import SendCredit from "./Public/SendCredit";
import FundHistory from "./Public/FundHistory";
import PrivateRoutes from "./config/PrivateRoutes";
import ActiveUsers from "./Public/ActiveUsers";
import InActiveUsers from "./Public/InActiveUsers";
import PendingTickets from "./Public/PendingTickets";
import ViewUser from "./Public/ViewUser";
import Income from "./Public/Income";
import IncomeByType from "./Public/IncomeByType";
import ROIHistory from "./Public/RoiHistory";
import BlockedUsers from "./Public/BlockedUsers";
import UserPopupImage from "./Public/UserPopupImage";
import WithdrawSettings from "./Public/WithdrawSettings";
import CommissionPackge from "./Public/CommissionPackage";
import PendingTRC20Withdraw from "./Public/PendingTRC20Withdraw";
import Trade from "./Public/Trade";
import TradeHistory from "./Public/TradeHistory";
import EditTrade from "./Public/EditTrade";

function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });


  function Loginlayout() {
    return (
      <>
        <div className="">
          {/* <Header /> */}
          <Outlet />
        </div>
      </>
    );
  }
  function MainLayout() {
    return (
      <>
        <div className="">
          <Header />
          <Outlet />
          {/* <Footer/> */}
        </div>
      </>
    );
  }
  

  return (
    <>
      <Router>
        <Routes>
          <Route element={<Loginlayout />}>
            <Route path="/*" element={<Login />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route element={<MainLayout />}>
              <Route path="/dashboard" element={<Index pageTitle={`Dashboard`} />} />
              <Route path="/table" element={<Table />} />
              <Route path="/form" element={<Form />} />
              <Route path="/user" element={<Users />} />
              <Route path="/paidUsers" element={<ActiveUsers />} />
              <Route path="/inactiveUsers" element={<InActiveUsers />} />
              <Route path="/blockedUsers" element={<BlockedUsers />} />
              <Route path="/news" element={<News />} />
              <Route path="/popup_upload" element={<UserPopupImage />} />
              <Route path="/withdrawsetting" element={<WithdrawSettings />} />
              <Route path="/comissionpackage" element={<CommissionPackge />} />
              <Route path="/CurrentUserPopup" element={<WorkingPopup />} />
              <Route path="/roiList" element={<ROIHistory />} />
              <Route path="/WithdrawSettings" element={<SiteIncome />} />
              <Route path="/allincomes" element={<Income />} />
              <Route path="/income/:type" element={<IncomeByType />} />
              <Route path="/Withdraw" element={<WithdrawRequest />} />
              <Route path="/Approved" element={<ApprovedWithdraw />} />
              <Route path="/Pending" element={<PendingWithdraw />} />
              <Route path="/trc2-Pending" element={<PendingTRC20Withdraw />} />

              <Route path="/Rejected" element={<RejectedWithdraw />} />
              <Route path="/adminpending" element={<AdminPendingwithdraw />} />
              <Route path="/pending-tickets" element={<PendingTickets />} />
              {/* <Route path="/solved-ticket" element={<ComposeMail />} /> */}
              <Route path="/solved-ticket" element={<SolvedTickets />} />
              <Route path="/SendWallet" element={<SendDeposit />} />
              <Route path="/SendIncomeWallet" element={<SendCredit />} />
              <Route path="/fund_history" element={<FundHistory />} />
              <Route path="/view-user/:username" element={<ViewUser />} />
              <Route path="/trade" element={<Trade />} />
              <Route path="/trade-history" element={<TradeHistory />} />
              <Route path="/edit-trade/:_id" element={<EditTrade />} />
              
            </Route>
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
