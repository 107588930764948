import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import LoadingBar from 'react-top-loading-bar';
import { Link } from "react-router-dom";
import toasted from "../config/toast";

const TradeHistory = () => {
  const [tradeHistory, setTradeHistory] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);

  useEffect(() => {
    getTradeHistory();
  }, [page, limit]);

  const getTradeHistory = async () => {
    setIsTrue(true);
    setProgress(10);

    let res = await _fetch(`${api_url}trade/trade-history`, "GET", {});
    console.log('res', res);
    
    if (res?.status === "success") {
      setTradeHistory(res?.data);
      setTotalRecords(res?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  };

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setPage(newOffset);
  };

  function searchSubmit() {
    setSearch(searchValue);
  }

  const handleDelete = async (_id: string) => {
    setProgress(10);
    const res = await _fetch(`${api_url}trade/delete-trade`, "POST", { _id });
    if (res?.status === "success") {
      toasted.success(res?.message);
      getTradeHistory(); 
    }
    else {
      toasted.error(res?.message);
    }
    setProgress(100);
  };

  return (
    <>
      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />
      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="in-table pb-4">
        <div className="top-simple-heading">
          <h1 className="heading-h1">Trade History</h1>
          {/* <div className="top-search">
            <form action="">
              <div className="top-sech">
                <input
                  className="input-fields"
                  type="search"
                  placeholder="Search Fund History "
                  onChange={(e: any) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
                <button type="submit" className="search-bs" onClick={searchSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div> */}
        </div>

        <div className="coustom_container">
          <div className="table_main table_main_comun">
            <div className="outer-table table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Symbol</th>
                    <th scope="col">Buy/Sell</th>
                    <th scope="col">Open Price</th>
                    <th scope="col">Close Price</th>
                    <th scope="col">Profit/Loss</th>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tradeHistory?.map((item: any, index: any) => (
                    <tr key={item.symbol}>
                      <td>{item?.symbol}</td>
                      <td style={{ color: item?.market_trade_type === 'sell' ? 'red' : 'green' }}>
                        {item?.market_trade_type ? item.market_trade_type.charAt(0).toUpperCase() + item.market_trade_type.slice(1) : ''}
                      </td>
                      <td>${item?.open_price}</td>
                      <td>${item?.close_price}</td>
                      <td style={{ color: item?.profit_loss > 0 ? "green" : "red" }}>
                        {item?.profit_loss !== undefined ? `${Math.abs(item.profit_loss).toFixed(2)}%` : ""}
                      </td>
                      <td> {dateformat(item?.createdAt, "UTC:dd-mm-yyyy hh:mm:ss")}</td>
                      <td className="main-btns-edits">
                        <Link className="edit-btn" to={`/edit-trade/${item?._id}`}>Edit</Link>
                        <button className="delete-btn" onClick={() => handleDelete(item?._id)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalRecords > 10 && (
                <ReactPaginate
                  className="pagination__2"
                  activeClassName="active"
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  pageCount={Math.ceil(totalRecords / limit)}
                  previousLabel="<"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeHistory;
